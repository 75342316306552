<script setup>
import { onMounted, watch } from 'vue'
import Income from '@/views/accounting/income.vue'
import Expense from '@/views/accounting/expense.vue'

definePage({
  meta: {
    action: 'read',
    subject: 'accounting',
  },
})

const tabIndex = ref(0)
const isLoading = ref(false)
const data= ref([])

const statistics = [
  {
    title: 'Earning',
    stats: '1.423k',
    icon: 'tabler-arrows-diff',
    color: 'primary',
  },
  {
    title: 'Income',
    stats: '230k',
    icon: 'tabler-arrows-left',
    color: 'success',
  },
  {
    title: 'Expense',
    stats: '8.549k',
    icon: 'tabler-arrows-right',
    color: 'error',
  },
]

const getData = async () => {

  isLoading.value = true
  await $api('costs/get_costs', {
    method: 'GET',
    onResponse: ({ response }) => {
      data.value = response._data
    },
  })
  isLoading.value = false
}



watch(tabIndex, () => {
  getData()
})

onMounted(() => {
  getData()
})
</script>

<template>
  <div>
    <VCard

      class="mb-2"
    >
      <VCardText>
        <VRow>
          <VCol
            v-for="item in statistics"
            :key="item.title"
            cols="6"
            md="3"
          >
            <div class="d-flex align-center gap-4  mt-0">
              <VAvatar
                :color="item.color"
                variant="tonal"
                rounded
                size="40"
              >
                <VIcon :icon="item.icon" />
              </VAvatar>

              <div class="d-flex flex-column">
                <h5 class="text-h5">
                  {{ item.stats }}
                </h5>
                <div class="text-sm">
                  {{ $t(item.title) }}
                </div>
              </div>
            </div>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VCard

      :loading="isLoading"
    >
      <VTabs
        v-model="tabIndex"
        :disabled="isLoading"
      >
        <VTab>
          {{ $t('Income') }}
        </VTab>
        <VTab>
          {{ $t('Expense') }}
        </VTab>
      </VTabs>
      <Income
        v-if="tabIndex==0"
        :data="data"
      />
      <Expense
        v-if="tabIndex==1"
        :data="data"
      />
    </VCard>
  </div>
</template>
